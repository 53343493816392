
















































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
  private isBurgerActive = false;

  public toggleBurger() {
    window.scrollTo(0, 0);
    this.isBurgerActive = !this.isBurgerActive;
  }
}
